<template>
  <div class="member_active">
    <!-- <div class="member_header">

        </div> -->
    <div class="newuser_register_confirm">
      <h3>新用户注册福利</h3>
      <img src="@/assets/image/member_title.png" alt="" class="title" />
      <!-- <p class="remaining_count">今日剩余名额：{{ count }}个</p> -->
      <div class="active_countdown">
        <p>
          活动倒计时：<span
            ><b>{{ timeFormat(day)[0] }}</b
            ><b>{{ timeFormat(day)[1] }}</b></span
          >天<span
            ><b>{{ timeFormat(hours)[0] }}</b
            ><b>{{ timeFormat(hours)[1] }}</b></span
          >时
          <span
            ><b>{{ timeFormat(minutes)[0] }}</b
            ><b>{{ timeFormat(minutes)[1] }}</b></span
          >分
          <span
            ><b>{{ timeFormat(seconds)[0] }}</b
            ><b>{{ timeFormat(seconds)[1] }}</b></span
          >秒
        </p>
      </div>
      <img
        src="@/assets/image/register_btn.png"
        @click="registerClick"
        class="register"
      />
    </div>
    <!-- 全站资源任意下载使用 -->
    <div class="allWebsite_material">
      <div class="material_cont">
        <div class="title">
          <p>全站资源任意下载使用</p>
          <label for="">音乐、音效、视频、AI语音</label>
        </div>
        <div class="website_description">
          <div class="material_type">
            <img src="../../assets/image/member_icon1.png" alt="" />
            <span>自媒体平台</span>
          </div>
          <div class="material_type">
            <img src="../../assets/image/member_icon2.png" alt="" />
            <span>个人直播/FM</span>
          </div>
          <div class="material_type">
            <img src="../../assets/image/member_icon3.png" alt="" />
            <span>产品内置用途</span>
          </div>
          <div class="material_type">
            <img src="../../assets/image/member_icon4.png" alt="" />
            <span>家庭视频</span>
          </div>
          <div class="material_type">
            <img src="../../assets/image/member_icon5.png" alt="" />
            <span>个人影视作品</span>
          </div>
          <div class="material_type">
            <img src="../../assets/image/member_icon6.png" alt="" />
            <span>AI语音合成下载</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 活动规则 -->
    <div class="active_rules">
      <label for="">活动规则：</label>
      <ul>
        <li>1、活动时间：2022年1月17日18:00:00至2022年3月15日18:00:00；</li>
        <li>2、活动有效期内，每天前100名新用户注册，赠送个人月会员一份，赠送的个人月会员有效期自注册完成时开始起算；</li>
        <li>3、赠送的会员不可退款、不可开具发票；</li>
        <li>4、赠送的会员可根据协议下载全站资源；</li>
        <li>5、参加该活动的用户，可与<span>推广分享</span>及<span>进群送会员</span>活动同享；</li>
        <li>6、如有疑问，可联系客服；</li>
        <li>7、本次活动最终解释权归北京数音未来科技有限公司所有。</li>
      </ul>
    </div>

    <!-- 提示 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      top="30vh"
      :show-close="false"
    >
      <div class="active_confirm">
        <img src="@/assets/image/confirm_icon.png" alt="" />
        <span>抱歉，活动太火爆了，今日名额已一抢而空。</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="register">不需要参加活动，立即注册</el-button>
        <el-button type="primary" @click="closeDialog">明天再来</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { timeFormat } from '@/utils/formDate.js'
import { getRegisterNum } from '@/api/member.js'
export default {
  data() {
    return {
      dialogVisible: false,
      timer: null,
      timers: null,
      count: 100, // 剩余名额
      day: '', // 天
      hours: '', // 时
      minutes: '', // 分
      seconds: '', // 秒
      active_endTime: new Date('2022/3/15, 18:00:00'),
      timeDurtion: [
        {beginTime: '23:50', endTime: '10:05', count: 100},
        {beginTime: '10:05', endTime: '11:45', count: 75},
        {beginTime: '11:45', endTime: '12:35', count: 54},
        {beginTime: '12:35', endTime: '15:10', count: 35},
        {beginTime: '15:10', endTime: '16:50', count: 29},
        {beginTime: '16:50', endTime: '17:30', count: 21},
        {beginTime: '17:30', endTime: '18:17', count: 11},
        {beginTime: '18:17', endTime: '23:50', count: 3}
      ]
    }
  },
  created() {
    this.$store.dispatch('user/setMemberstatus', false)
    this.timer = setInterval(() => {
      this.FreshTime()
    }, 1000)
    // 轮询查当天剩余名额次数 跳出当前页面 清空计时器
    // let that = this
    // this.timers = setInterval(function () {
    //   setTimeout(() => {
    //     that.getRegisterNum()
    //   }, 1)
    // }, 1000)
    // this.$once('hook:beforeDestroy', () => {
    //   clearInterval(this.timers)
    //   this.timers = null
    // })
  },
  beforeRouteLeave(to, from, next){
    //   console.log(to, from)
      if(to.fullPath !== '/activity/active'){
        this.$store.dispatch('user/setMemberstatus', true)
      }
      next()
  },
  methods: {
    timeFormat(date) {
      return timeFormat(date)
    },
    time_range(beginTime, endTime, nowTime, count){
      var strb = beginTime.split (":");
      if (strb.length != 2) {
        return false;
      }
    
      var stre = endTime.split (":");
      if (stre.length != 2) {
        return false;
      }
    
      var strn = nowTime.split (":");
      if (stre.length != 2) {
        return false;
      }
      var b = new Date ();
      var e = new Date ();
      var n = new Date ();
      b.setHours (strb[0]);
      b.setMinutes (strb[1]);
      e.setHours (stre[0]);
      e.setMinutes (stre[1]);
      n.setHours (strn[0]);
      n.setMinutes (strn[1]);
      // console.log(b.getTime(), e.getTime())
      if(n.getTime() >= b.getTime() && n.getTime() < e.getTime()){
        this.count = count
      }
    },
    FreshTime() {
      const nowTime = new Date() // 当前时间
      const h = nowTime.getHours() < 10 ? '0'+nowTime.getHours() : nowTime.getHours() ;
      const m = nowTime.getMinutes() < 10 ? '0'+nowTime.getMinutes() : nowTime.getMinutes();
      const str = h + ':' + m;
      // console.log(str)
      for(var i=0;i<this.timeDurtion.length; i++){
        this.time_range(this.timeDurtion[i].beginTime, this.timeDurtion[i].endTime, str, this.timeDurtion[i].count)
      }

      const spaceTime = parseInt(
        (this.active_endTime.getTime() - nowTime.getTime()) / 1000
      )
      if (spaceTime == 0) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.day =
        parseInt(spaceTime / (24 * 60 * 60)) < 10
          ? '0' + parseInt(spaceTime / (24 * 60 * 60))
          : parseInt(spaceTime / (24 * 60 * 60))
      this.hours =
        parseInt((spaceTime / (60 * 60)) % 24) < 10
          ? '0' + parseInt((spaceTime / (60 * 60)) % 24)
          : parseInt((spaceTime / (60 * 60)) % 24)
      this.minutes =
        parseInt((spaceTime / 60) % 60) < 10
          ? '0' + parseInt((spaceTime / 60) % 60)
          : parseInt((spaceTime / 60) % 60)
      this.seconds =
        parseInt(spaceTime % 60) < 10
          ? '0' + parseInt(spaceTime % 60)
          : parseInt(spaceTime % 60)
      // console.log(this.day.toString().split(''));
    },
    /**
     * 抢先注册
     */
    registerClick() {
      this.getRegisterNum();
    },
    /**
     * 点击不需要参加活动 立即注册
     */
    register(){
      this.dialogVisible = false
      this.$store.dispatch('user/openRegister')
    },
    /**
     * 点击明天再来按钮
     */
    closeDialog(){
      this.dialogVisible = false;
      this.$router.push({path:'/'})
    },
    /**
     * 查询活动期间注册剩余名额
     */
    async getRegisterNum() {
      const res = await getRegisterNum()
      // console.log(res)
      if (res.code == '0') {
        if (res.data.num == 0) {
          this.dialogVisible = true
        } else {
          this.$store.dispatch('user/openRegister')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/member.scss";
.active_confirm {
  display: flex;
  align-items: center;
  img {
    width: 36px;
  }
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: inline-block;
    padding-left: 10px;
  }
}
</style>
